import clsx from 'clsx';
import preval from 'preval.macro';
import { useMemo, useState } from 'react';
import { Link, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ReactComponent as RightArrow } from 'src/assets/svg/RightFillArrow.svg';
import { ReactComponent as Logo } from 'src/assets/svg/logo.svg';
import SvgUser from 'src/assets/svg/user.svg';
import { Toast } from 'src/components/Toast';
import { AuthRoute, Blank } from 'src/components/common';
import { Icon } from 'src/components/common/icons';
import { NotificationModal } from 'src/components/notification/NotificationModal';
import { Constants } from 'src/constants';
import {
  externalCreateToken,
  useDashboardGetDashBoardData,
  useNotificationLogFindRecent,
} from 'src/generated/endpoint';
import { ResponseDashboardDto, Role } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { meState, newMsgCntState } from 'src/store';
import { DateFormat, DateUtil } from 'src/util/date';
import { globalEnv } from 'src/util/global-env';
import { useAuth, useLogout } from 'src/util/hooks';
import { getNickName } from 'src/util/status';
import AbsentComparisonPage from './absent/AbsentComparisonPage';
import { AbsentPage } from './absent/AbsentPage';
import { TeacherApplyPage } from './absent/TeacherApplyPage';
import { ActivityDetailPage } from './activity/ActivityDetailPage';
import { ActivityPage } from './activity/ActivityPage';
import { ActivityV3AddPage } from './activityv3/ActivityV3AddPage';
import { ActivityV3DetailPage } from './activityv3/ActivityV3DetailPage';
import { ActivityV3Page } from './activityv3/ActivityV3Page';
import { ActivityV3ReportPage } from './activityv3/ActivityV3ReportPage';
import { ActivityV3SessionAddPage } from './activityv3/ActivityV3SessionAddPage';
import { ActivityV3SessionDetailPage } from './activityv3/ActivityV3SessionDetailPage';
import { ActivityV3SessionReportPage } from './activityv3/ActivityV3SessionReportPage';
import { ActivityV3SessionUpdatePage } from './activityv3/ActivityV3SessionUpdatePage';
import { ActivityV3UpdatePage } from './activityv3/ActivityV3UpdatePage';
import AnnouncementPage from './announcement/AnnouncementPage';
import { AttendancePage } from './attendance/AttendancePage';
import { BoardsPage } from './board/BoardPage';
import { CalendarPage } from './calendar/CalendarPage';
import { CanteenPage } from './canteen/CanteenPage';
import { ChatListPage } from './chat/ChatListPage';
import { FieldtripMainPage } from './fieldtrip/FieldtripMainPage';
import { FieldtripNoticePage } from './fieldtrip/FieldtripNoticePage';
import { FieldtripResultPage } from './fieldtrip/FieldtripResultPage';
import { GroupPage } from './group/GroupPage';
import { HistoryPage } from './history/HistoryPage';
import { LoginPage } from './login/LoginPage';
import { TeacherFirstLoginPage } from './login/TeacherFirstLoginPage';
import { NotificationSettingsPage } from './mypage/NotificationSettingsPage';
import { TeacherInfoPage } from './mypage/TeacherInfoPage';
import { TeacherMyPage } from './mypage/TeacherMyPage';
import { NewsletterPage } from './newsletter/NewsletterPage';
import { NoticePage } from './notice/NoticePage';
import { OutingPage } from './outing/OutingPage';
import { RecordPage } from './record/RecordPage';
import { StudentCardPage } from './studentcard/StudentCardPage';
import { TimetablePage } from './timetable/TimetablePage';

export function TeacherMainPage() {
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const { t, changeLanguage, currentLang } = useLanguage();

  const setNewMsgCnt = useSetRecoilState(newMsgCntState);
  const me = useRecoilValue(meState);
  const manuals = [
    {
      id: 1,
      url: 'https://superschoolofficial.notion.site/f9bae37feef94ee7b9f886b5e074fdac',
      title: t('teacher_usage_guide'),
    },
    {
      id: 2,
      url: 'https://superschoolofficial.notion.site/e8ebd5829e2846ab8e97417c7ab589f7',
      title: t('student_usage_guide'),
    },
    {
      id: 3,
      url: 'https://superschoolofficial.notion.site/70491392ea96454f8688cffee395c1c7',
      title: t('parent_usage_guide'),
    },
    // {
    //   id: 1,
    //   url: 'https://www.notion.so/signed/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fb072a08c-df63-4eb5-84fa-2e084026f390%2Fstarting.pdf?table=block&id=9873b81d-5059-4043-9471-ad187bfd8f46&spaceId=8612a4a1-fd50-4ddd-9f79-e0df30e67f9e&name=starting.pdf&userId=625b3019-b1d4-48ee-a73f-fc5f93cb5b0a&cache=v2',
    //   title: '시작하기',
    // },
    // {
    //   id: 2,
    //   url: 'https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%EC%B6%9C%EA%B2%B0%281%29_2022.11.01.pdf',
    //   title: '출결관리',
    // },
    // {
    //   id: 3,
    //   url: 'https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%EC%B6%9C%EA%B2%B0%282%29_%EC%98%A8%EB%9D%BC%EC%9D%B8%EC%B6%9C%EC%84%9D%EB%B6%80_2022.11.01.pdf',
    //   title: '출석부',
    // },
    // {
    //   id: 4,
    //   url: 'https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%EC%B2%B4%ED%97%98%ED%95%99%EC%8A%B5_2022.11.01.pdf',
    //   title: '체험학습',
    // },
    // {
    //   id: 5,
    //   url: 'https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%ED%99%9C%EB%8F%99%281%29_2022.11.01.pdf',
    //   title: '활동기록부',
    // },
    // {
    //   id: 6,
    //   url: 'https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%ED%95%99%EB%B6%80%EB%AA%A8%EC%9A%A9_2022.10.31.pdf',
    //   title: '보호자용',
    // },
  ];

  const logout = useLogout();
  const [open, setOpen] = useState(false);
  const [openFieldTrip, setOpenFieldTrip] = useState(false);
  const [blankOpen, setBlankOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { authenticated } = useAuth();
  const { data: notificationLog } = useNotificationLogFindRecent();

  if (me?.role === 'USER' || me?.role === 'PARENT') {
    replace('/student');
  }

  if (me?.firstVisit && !pathname.startsWith('/teacher/first-login')) {
    replace('/teacher/first-login');
  }

  // TODO 채팅 머지 후, 재 수정, 신규 메시지
  const newMsgCnt = useRecoilValue(newMsgCntState);

  const tabs = useMemo(
    () => [
      { path: '/teacher/activityv3', icon: Icon.Activity, name: '활동' },
      {
        path: '/teacher/apply',
        icon: Icon.Planner,
        name: '출결',
        extra: [
          '/teacher/absent',
          '/teacher/outing',
          '/teacher/fieldtrip',
          '/teacher/attendance',
          '/teacher/studentcard',
          '/teacher/timetable',
        ],
      },
      {
        path: `/teacher/canteen/${DateUtil.formatDate(new Date().toISOString(), DateFormat['YYYY-MM-DD'])}`,
        icon: Icon.Home,
        name: '홈',
        extra: ['/teacher/canteen', '/teacher/chat'],
      },
      {
        path: '/teacher/notice',
        icon: Icon.Notice,
        name: '공지',
        extra: ['/teacher/board', '/teacher/newsletter'],
      },
      {
        path: '/teacher/mypage',
        icon: Icon.MoreVertical,
        name: '더보기',
        extra: ['/teacher/update', '/teacher/announcement'],
      },
    ],
    [me?.role, authenticated, pathname],
  );

  const adminPermission = useMemo(
    () =>
      me?.teacherPermission?.adminApprovalLine ||
      me?.teacherPermission?.adminClass ||
      me?.teacherPermission?.adminGroup ||
      me?.teacherPermission?.adminParent ||
      me?.teacherPermission?.adminSms ||
      me?.teacherPermission?.adminScore ||
      me?.teacherPermission?.adminStudent ||
      me?.teacherPermission?.adminTeacher ||
      me?.teacherPermission?.adminTimetable,
    [me],
  );

  function openSugang() {
    externalCreateToken().then((token) => {
      window.open(`https://${window.location.hostname}/course/teacher?sso=${token}`, '_self');
    });
  }

  useDashboardGetDashBoardData<ResponseDashboardDto>({
    query: {
      onSuccess: (res) => {
        setNewMsgCnt(res?.unreadChatMessageCount || 0);
      },
    },
  });

  const hasConfirmedAll = !notificationLog;

  return (
    <div className="flex">
      {blankOpen && <Blank />}

      <div className="hidden w-[270px] flex-shrink-0 md:block">
        <div className="flex flex-grow flex-col border-r border-gray-200 bg-gray-50 pb-4 pt-1 ">
          <div className="flex items-center justify-between px-4">
            <Link to={`/teacher/canteen/${DateUtil.formatDate(new Date().toISOString(), DateFormat['YYYY-MM-DD'])}`}>
              <Logo className="w-20" />
            </Link>
            <div className="flex items-center space-x-4">
              {me?.schoolId === 2 || me?.schoolId === 171 || me?.schoolId === 183 ? (
                <div
                  onClick={() => {
                    changeLanguage();
                  }}
                  className="cursor-pointer text-sm text-brand-1"
                >
                  {t('select_language')}
                </div>
              ) : (
                <div
                  onClick={() => {
                    setBlankOpen(true);
                    window?.location?.reload();
                  }}
                  className="cursor-pointer text-sm text-brand-1"
                >
                  새로고침
                </div>
              )}
              <div className="relative h-6 w-6">
                <Icon.Bell className="h-6 w-6 cursor-pointer" onClick={() => setModalOpen(!modalOpen)} />
                {!hasConfirmedAll && <div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-red-500" />}
                {modalOpen && (
                  <div className="scroll-box absolute -top-2 left-12 z-50 h-128 w-96 overflow-y-auto overflow-x-hidden">
                    <NotificationModal />
                    <Icon.Close
                      className="absolute left-[22.5rem] top-2 h-4 w-4 cursor-pointer"
                      onClick={() => setModalOpen(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* 선생님 프로필 */}
          <div className="mx-4 flex select-none items-center gap-4  py-2">
            <div className="flex h-[72px] w-[60px] rounded-xl">
              <img
                className="mx-auto rounded-xl"
                src={`${Constants.imageUrl}${me?.profile}`}
                alt=""
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = SvgUser;
                  currentTarget.className = 'w-full rounded-xl';
                }}
              />
            </div>
            <div className="flex h-[72px] flex-1 flex-col justify-between gap-3 py-1 lg:px-2">
              <div>
                <div
                  className="flex cursor-pointer items-center justify-between"
                  onClick={() => replace('/teacher/update')}
                >
                  <p className="text-14 font-bold">
                    {me?.name}
                    {getNickName(me?.nickName)} {t(`teacher`)}
                  </p>
                  <RightArrow />
                </div>
                <p className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium leading-none">
                  {me?.email}
                </p>
              </div>
              <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-xs font-normal leading-none">
                {me?.school?.name}
              </div>
            </div>
          </div>
          {/* 메뉴바 */}
          <div className="scroll-box mt-5 flex h-screen-13 flex-grow flex-col overflow-y-auto tracking-tighter">
            <nav
              className={`flex-1 space-y-8 px-4 ${currentLang === 'en' ? 'text-14' : 'text-16'}`}
              aria-label="Sidebar"
            >
              <div className="space-y-1">
                <div className="flex">
                  {/* 출결 start */}
                  <div className="w-20 self-center text-center font-bold text-darkgray">{t(`attendance`)}</div>
                  <div className="my-2.5 ml-3 mr-2 border border-grey-6" />
                  <div className="w-40">
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/attendance"
                      className={
                        pathname.startsWith('/teacher/attendance')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`attendance_register`)}
                    </Link>
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/timetable"
                      className={
                        pathname.startsWith('/teacher/timetable')
                          ? 'group flex items-center whitespace-pre-line rounded-md bg-darkgray px-3 py-3 text-sm font-semibold text-white'
                          : 'font-base group flex cursor-pointer items-center whitespace-pre-line rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {currentLang === 'en'
                        ? `${t(`timetable`)}\n/ ${t(`attendance_check`)}`
                        : `${t(`timetable`)}/${t(`attendance_check`)}`}
                    </Link>
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/outing"
                      className={
                        pathname.startsWith('/teacher/outing')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`certificate`)}
                    </Link>
                    <div>
                      {pathname.startsWith('/teacher/absent') ? (
                        <div className="rounded-md bg-gray-200">
                          <Link
                            onClick={() => setOpenFieldTrip(false)}
                            to="/teacher/absent"
                            className={
                              !pathname.startsWith('/teacher/absent/comparison')
                                ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white'
                                : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                            }
                          >
                            {t(`Custom.SID${me?.schoolId}.absentTitle`, '출결신고서')}
                          </Link>
                          {me?.schoolId !== 171 && (
                            <Link
                              to="/teacher/absent/comparison"
                              className={
                                pathname.startsWith('/teacher/absent/comparison')
                                  ? 'group flex items-center rounded-md bg-darkgray px-3 py-2 text-sm font-bold text-white'
                                  : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-2 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                              }
                            >
                              - {t(`compare_neis_data`)}
                            </Link>
                          )}
                        </div>
                      ) : (
                        <Link
                          onClick={() => setOpenFieldTrip(false)}
                          to="/teacher/absent"
                          className={
                            pathname.startsWith('/teacher/absent')
                              ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white'
                              : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                          }
                        >
                          {t(`Custom.SID${me?.schoolId}.absentTitle`, '출결신고서')}
                        </Link>
                      )}
                    </div>

                    <div
                      className={
                        openFieldTrip
                          ? 'font-base group flex cursor-pointer select-none items-center rounded-t-md bg-gray-200 px-3 pb-1 pt-3 text-sm text-darkgray'
                          : 'font-base group flex cursor-pointer select-none items-center rounded-t-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                      onClick={() => setOpenFieldTrip(!openFieldTrip)}
                    >
                      {t(`experiential_learning_activity`)}
                    </div>
                    {openFieldTrip && (
                      <div className="rounded-b-md bg-gray-200">
                        <Link
                          to="/teacher/fieldtrip"
                          className={
                            pathname.startsWith('/teacher/fieldtrip') &&
                            !pathname.includes('notice') &&
                            !pathname.includes('result')
                              ? 'group flex items-center rounded-md bg-darkgray px-3 py-2 text-sm font-bold text-white'
                              : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-2 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                          }
                        >
                          - {t(`application_form`)}
                        </Link>
                        <Link
                          to="/teacher/fieldtrip/notice"
                          className={
                            pathname.startsWith('/teacher/fieldtrip/notice')
                              ? 'group flex items-center rounded-md bg-darkgray px-3 py-2 text-sm font-bold text-white'
                              : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-2 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                          }
                        >
                          - {t(`notification_form`)}
                        </Link>
                        <Link
                          to="/teacher/fieldtrip/result"
                          className={
                            pathname.startsWith('/teacher/fieldtrip/result')
                              ? 'group flex items-center rounded-md bg-darkgray px-3 py-2 text-sm font-bold text-white'
                              : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-2 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                          }
                        >
                          - {t(`report_form`)}
                        </Link>
                      </div>
                    )}
                    {(me?.role === Role.ADMIN ||
                      me?.role === Role.HEAD ||
                      me?.role === Role.PRE_HEAD ||
                      me?.role === Role.PRE_PRINCIPAL ||
                      me?.role === Role.PRINCIPAL ||
                      me?.role === Role.VICE_PRINCIPAL ||
                      me?.role === Role.HEAD_PRINCIPAL ||
                      me?.klassGroupId) && (
                      <Link
                        onClick={() => setOpenFieldTrip(false)}
                        to="/teacher/history"
                        className={
                          pathname.startsWith('/teacher/history')
                            ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white'
                            : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                        }
                      >
                        {t(`attendance_documents_management`)}
                      </Link>
                    )}
                  </div>
                </div>
                <div className="w-full border border-grey-6" />
                {/* 출결 end */}

                {/* 정보 start */}
                <div className="flex">
                  <div className="w-20 self-center text-center font-bold text-darkgray">{t(`information`)}</div>

                  <div className="my-2.5 ml-3 mr-2 border border-grey-6" />

                  <div className="w-40">
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/studentcard"
                      className={
                        pathname.startsWith('/teacher/studentcard')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`student_information`)}
                    </Link>
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/groups"
                      className={
                        pathname.startsWith('/teacher/groups')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white '
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`group_information`)}
                    </Link>
                  </div>
                </div>
                <div className="w-full border border-grey-6" />
                {/* 정보 end */}

                {/* 활동 start*/}
                <div className="flex">
                  <div className="w-20 self-center text-center font-bold text-darkgray">{t(`activity`)}</div>
                  <div className="my-2.5 ml-3 mr-2 border border-grey-6" />
                  <div className="w-40">
                    <Link
                      to="/teacher/activityv3"
                      className={
                        pathname.startsWith('/teacher/activityv3')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white '
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`activity_record`)}
                    </Link>

                    {me?.email === 'superstudy@korea.kr' && (
                      <Link
                        to="/teacher/activity"
                        className={
                          pathname.startsWith('/teacher/groups')
                            ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white '
                            : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                        }
                      >
                        활동 기록부(old)
                      </Link>
                    )}

                    {me?.email === 'superstudy@korea.kr' && (
                      <Link
                        to="/teacher/record"
                        className={
                          pathname.startsWith('/teacher/record')
                            ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white '
                            : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                        }
                      >
                        생활 기록부(old)
                      </Link>
                    )}
                  </div>
                </div>
                {/* 활동 end*/}

                {/* 일정 start */}
                <div className="w-full border border-grey-6" />
                <div className="flex">
                  <div className="w-20 self-center text-center font-bold text-darkgray">{t(`schedule`)}</div>

                  <div className="my-2.5 ml-3 mr-2 border border-grey-6" />

                  <div className="w-40">
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/calendar"
                      className={
                        pathname.startsWith('/teacher/calendar')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`calendar`)}
                    </Link>
                    {/* <Link
                      to="/teacher/timetable"
                      className={
                        pathname.startsWith('/teacher/timetable')
                          ? 'bg-darkgray text-white group flex items-center px-3 py-3 text-sm font-semibold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-3 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      시간표
                    </Link> */}
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to={`/teacher/canteen/${DateUtil.formatDate(new Date().toISOString(), DateFormat['YYYY-MM-DD'])}`}
                      className={
                        pathname.startsWith('/teacher/canteen')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-semibold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`meal_plan`)}
                    </Link>
                  </div>
                </div>
                <div className="w-full border border-grey-6" />
                {/* 일정 end */}
                {/* 공지 start */}
                <div className="flex">
                  <div className="w-20 self-center text-center font-bold text-darkgray">{t(`notice`)}</div>

                  <div className="my-2.5 ml-3 mr-2 border border-grey-6" />

                  <div className="w-40">
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/notice"
                      className={
                        pathname.startsWith('/teacher/notice')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-semibold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`announcement`)}
                    </Link>
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/board"
                      className={
                        pathname.startsWith('/teacher/board')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-semibold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`class_bulletin_board`)}
                    </Link>
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/newsletter"
                      className={
                        pathname.startsWith('/teacher/newsletter')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-semibold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`parent_letters`)}
                    </Link>
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/chat"
                      className={
                        pathname.startsWith('/teacher/chat')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-semibold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      <div className="flex-1 text-left">{t(`messages`)}</div>
                      <div className="text-right">
                        {Number(newMsgCnt) > 0 ? (
                          <small className="inline-block h-6 w-6 rounded-full bg-red-500 text-center text-xs leading-6 text-white">
                            N {/* {Number(newMsgCnt)} */}
                          </small>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="w-full border border-grey-6" />
                {/*공지 end*/}

                {/* 서비스 start */}
                <div className="flex">
                  <div className="w-20 self-center text-center font-bold text-darkgray">{t(`more`)}</div>
                  <div className="my-2.5 ml-3 mr-2 border border-grey-6" />
                  <div className="w-40">
                    <Link
                      onClick={() => setOpenFieldTrip(false)}
                      to="/teacher/update"
                      className={
                        pathname.startsWith('/teacher/update')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-semibold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`my_information`)}
                    </Link>
                    <a
                      href="http://superstudy.channel.io/"
                      target="blank"
                      className="font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900"
                    >
                      {t(`contact_us`)}
                    </a>
                    <div
                      className={
                        open
                          ? 'font-base group flex cursor-pointer select-none items-center rounded-md bg-gray-200 px-3 py-3 text-sm text-darkgray'
                          : 'font-base group flex cursor-pointer select-none items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                      onClick={() => {
                        setOpenFieldTrip(false);
                        setOpen(!open);
                      }}
                    >
                      {t(`usage_guide`)}
                    </div>
                    {open && (
                      <>
                        {manuals.map(({ id, url, title }) => (
                          <a key={id} href={url} target="_blank" rel="noreferrer" download>
                            <div className="font-base group flex cursor-pointer select-none items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900">
                              {title}
                            </div>
                          </a>
                        ))}
                      </>
                    )}

                    <Link
                      to="/teacher/announcement"
                      className={
                        pathname.startsWith('/teacher/announcement')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-semibold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      {t(`superschool_announcement`)}
                    </Link>
                    <div
                      className="font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900"
                      onClick={() => logout()}
                    >
                      {t(`logout`)}
                    </div>
                  </div>
                </div>
                <div className="w-full border border-grey-6" />
                {/* 서비스 end */}
                {(me?.role === Role.ADMIN || adminPermission) && (
                  <Link
                    onClick={() => setOpenFieldTrip(false)}
                    to="/admin"
                    className="flex items-center justify-between rounded-md border-2 border-darkgray py-3 text-sm font-semibold"
                  >
                    <div className="flex w-full justify-center">{t('admin_mode')}</div>
                    <Icon.ChevronRight />
                  </Link>
                )}

                { me?.school?.isCourseActive && (
                  <div
                    onClick={() => {
                      setOpenFieldTrip(false);
                      openSugang();
                    }}
                    className="flex cursor-pointer items-center justify-between rounded-md border-2 border-darkgray py-3 text-sm font-semibold"
                  >
                    <div className="flex w-full justify-center">수강신청 설정</div>
                    <Icon.ChevronRight />
                  </div>
                )}

                <div className="py-2 text-12 text-gray-400">
                  <div className="text-white">
                    v{globalEnv.version} build at <br />
                    {preval`module.exports = new Date().toLocaleString().split("├")[0]`}
                  </div>
                  Copyright 2023. SUPERSCHOOL <br />
                  all right reserved.
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/*/!* Mobile V *!/*/}
      <nav className="bottom-nav z-100 md:hidden">
        {tabs.map((tab) => {
          const active = [tab.path, ...(tab.extra ?? [])].some((path) => pathname.startsWith(path));
          return (
            <Link key={tab.path} to={tab.path} className={clsx('bottom-nav-item', active && 'text-darkgray')}>
              <tab.icon className="stroke-current" />
              <span>{tab.name}</span>
            </Link>
          );
        })}
      </nav>

      <div className="scroll-box h-screen w-full grid-cols-6 overflow-x-hidden overflow-y-scroll md:grid md:overflow-y-hidden">
        <Switch>
          <Route path="/teacher/canteen" component={CanteenPage} />
          <Route path="/teacher/timetable" component={TimetablePage} />
          <Route path="/teacher/attendance" component={AttendancePage} />
          <Route path="/teacher/absent/comparison" component={AbsentComparisonPage} />
          <Route path="/teacher/absent" component={AbsentPage} />
          <Route path="/teacher/history" component={HistoryPage} />
          <Route path="/teacher/update" component={TeacherInfoPage} />
          <AuthRoute path="/teacher/first-login" component={TeacherFirstLoginPage} />
          <Route path="/teacher/fieldtrip/notice" component={FieldtripNoticePage} />
          <Route path="/teacher/fieldtrip/result" component={FieldtripResultPage} />
          <Route path="/teacher/board" component={BoardsPage} />
          <Route path="/teacher/chat" component={ChatListPage} />
          <Route path="/teacher/fieldtrip" component={FieldtripMainPage} />
          <Route path="/teacher/calendar" component={CalendarPage} />
          <Route path="/teacher/activityv3/:id/session/add" component={ActivityV3SessionAddPage} />
          <Route path="/teacher/activityv3/:id/session/:sessionId/update" component={ActivityV3SessionUpdatePage} />
          <Route path="/teacher/activityv3/:id/session/:sessionId/:studentId" component={ActivityV3SessionReportPage} />
          <Route path="/teacher/activityv3/:id/session/:sessionId" component={ActivityV3SessionDetailPage} />
          <Route path="/teacher/activityv3/add" component={ActivityV3AddPage} />
          <Route path="/teacher/activityv3/:id/update" component={ActivityV3UpdatePage} />
          <Route path="/teacher/activityv3/:id/:studentId" component={ActivityV3ReportPage} />
          <Route path="/teacher/activityv3/:id" component={ActivityV3DetailPage} />
          <Route path="/teacher/activityv3" component={ActivityV3Page} />
          <Route path="/teacher/activity/:id" component={ActivityDetailPage} />
          <Route path="/teacher/activity" component={ActivityPage} />
          <Route path="/teacher/record" component={RecordPage} />
          <Route path="/teacher/outing" component={OutingPage} />
          <Route path="/teacher/studentcard" component={StudentCardPage} />
          <Route path="/teacher/groups" component={GroupPage} />
          <Route path="/teacher/notice" component={NoticePage} />
          <Route path="/teacher/newsletter" component={NewsletterPage} />
          <Route path="/teacher/apply" component={TeacherApplyPage} />
          <Route path="/teacher/mypage" component={TeacherMyPage} />
          <Route path="/teacher/notification-settings" component={NotificationSettingsPage} />
          <Route path="/teacher/login" component={LoginPage} />
          <Route path="/teacher/announcement" component={AnnouncementPage} />

          <Route path="/teacher">
            {process.env.REACT_APP_MENU_TYPE === '2' ? (
              <Redirect to="/teacher/absent" />
            ) : (
              <Redirect
                to={`/teacher/canteen/${DateUtil.formatDate(new Date().toISOString(), DateFormat['YYYY-MM-DD'])}`}
              />
            )}
          </Route>
        </Switch>
      </div>

      <Toast />
    </div>
  );
}
