import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useStudentTestScore } from 'src/container/student-semesters-score';
import { getThisYear } from 'src/util/time';
import { List } from '../common';

interface MockScoreBoardProps {
  studentId: string;
}

export default function MockScoreBoard({ studentId }: MockScoreBoardProps) {
  // const thisYear = new Date().getFullYear();
  const thisYear = Number(getThisYear());
  const { scores, isLoading } = useStudentTestScore(Number(studentId), thisYear);
  const [month, setMonth] = useState<number | null>(null);
  const [filteredScores, setFilteredScores] = useState<any[]>([]);

  useEffect(() => {
    if (!scores || scores.length === 0) {
      setMonth(null);
      setFilteredScores([]);
      return;
    }

    const firstScoreSet = scores[0]?.scores || [];
    const availableMonths = _.sortBy(_.uniq(_.map(firstScoreSet, 'month')));

    if (availableMonths.length > 0) {
      const initialMonth = availableMonths[0]; // 첫 번째 month를 초기값으로 설정
      setMonth(initialMonth);

      // 초기 month에 해당하는 데이터 필터링
      const initialFilteredScores = firstScoreSet
        .filter((score: any) => score.month === initialMonth)
        .sort((a: any, b: any) => (a.subject < b.subject ? -1 : a.subject > b.subject ? 1 : 0)); // 한글 오름차순 정렬
      setFilteredScores(initialFilteredScores);
    }
  }, [scores]);

  const handleMonthChange = (selectedMonth: number) => {
    const firstScoreSet = scores[0]?.scores || [];
    const filtered = firstScoreSet
      .filter((score: any) => score.month === selectedMonth)
      .sort((a: any, b: any) => (a.subject < b.subject ? -1 : a.subject > b.subject ? 1 : 0)); // 한글 오름차순 정렬
    setMonth(selectedMonth);
    setFilteredScores(filtered);
  };

  return (
    <>
      <div className="flex w-full flex-col items-center space-x-2 md:flex-row">
        <List className="flex flex-row gap-2 md:m-0">
          {scores && scores.length > 0 ? (
            <>
              {_.sortBy(_.uniq(_.map(scores[0]?.scores || [], 'month'))).map((availableMonth, index) => (
                <React.Fragment key={availableMonth}>
                  <span
                    onClick={() => handleMonthChange(availableMonth)}
                    className={`${
                      month === availableMonth ? 'font-bold' : 'text-gray-300'
                    } cursor-pointer transition-all hover:font-bold hover:text-black`}
                  >
                    {availableMonth}월 모의고사
                  </span>
                  {index < scores[0]?.scores.length - 1 && <span className="mx-2 text-gray-300">|</span>}
                </React.Fragment>
              ))}
            </>
          ) : (
            <></>
          )}
        </List>
      </div>
      <div className="relative mt-4 overflow-x-auto rounded-lg text-14 shadow-md">
        <table className="w-full text-left text-gray-500 rtl:text-right">
          <thead className="bg-gray-200 uppercase text-gray-700">
            <tr>
              <th scope="col" className="h-4 w-1/6 border-r border-gray-300 p-2 text-center">
                영역
              </th>
              <th scope="col" className="h-4 w-1/6 border-r border-gray-300 p-2 text-center">
                과목
              </th>
              <th scope="col" className="w-1/6 border-r border-gray-300 px-2 py-2 text-center">
                원점수
              </th>
              <th scope="col" className="w-1/6 border-r border-gray-300 px-2 py-2 text-center">
                표준점수
              </th>
              <th scope="col" className="w-1/6 border-r border-gray-300 px-2 py-2 text-center">
                백분위
              </th>
              <th scope="col" className="w-1/6 px-2 py-2 text-center">
                등급
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredScores.length > 0 ? (
              filteredScores.map((score, index) => (
                <tr key={index} className="border-b bg-white">
                  <td className="border-b border-r border-gray-300 px-2 py-2 text-center">{score.subject}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">
                    {['탐구영역1', '탐구영역2', '탐구선택2', '탐구선택3'].includes(score.subject)
                      ? score.optional_subject || '-'
                      : score.sub_subject || '-'}
                  </td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">{score.raw_score || '-'}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">{score.standard_score || '-'}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">{score.percentile || '-'}</td>
                  <td className="px-2 py-2 text-center">{score.rank || '-'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="px-2 py-2 text-center">
                  데이터가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
