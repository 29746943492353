export function TermsOfUse() {
  return (
    <div className="h-screen overflow-y-auto p-8 leading-8">
      <h1 className={'text-4xl font-bold'}>이용약관</h1>
      <p className={'font-bold'}>이용약관</p>
      <p className={'font-bold'}>총칙</p>
      <p className={'font-bold'}>제1조 (목적)</p>
      이 약관은 주식회사 슈퍼스쿨(이하 “회사”)와 이용자 간에 회사가 제공하는 ‘슈퍼플래너’, ‘슈퍼스페이스’, ‘슈퍼스쿨’ 및
      회사가 운영하는 기타 서비스(이하 “서비스”)를 이용자가 이용함에 있어 회사와 이용자 간의 권리, 의무 및 책임,
      이용조건 및 절차 등에 관한 기본적인 사항을 규정함을 목적으로 합니다.
      <br />
      <p className={'font-bold'}>제2조 (용어의 정의)</p>
      1. 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
      <br />
      <p className={'pl-5'}>
        a. 서비스 : 이용매체(PC, 휴대형 단말기 등 각종 유무선 장치)를 불문하고 회사가 운영 및 제공하며 이용자가 이용할
        수 있는 ‘슈퍼플래너’, ‘슈퍼스페이스’, ‘슈퍼스쿨’ 및 회사가 운영하는 기타 서비스를 말합니다.
        <br />
        b. 이용자 : 서비스에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 이용하는 회원 및 비회원을 말합니다.
        <br />
        c. 이용계약 : 회사의 서비스를 제공받고 이용하기 위하여 체결하는 계약을 말합니다.
        <br />
        d. 회원 : 이 약관에 동의하고 회사와 이용계약을 체결하여 회원등록을 한 자로서, 회사가 제공하는 서비스를 이용하는
        자를 말합니다.
        <br />
        e. 비회원 : 이용계약을 체결하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
        <br />
        f. 사이트 : 회사가 서비스를 이용자에게 제공하고자 컴퓨터 등 정보통신설비를 이용하여 개설한 가상의 영업장을
        말합니다.
        <br />
        g. 회원정보 : 회원이 서비스를 이용하기 위해 이용계약 체결시 입력하는 아이디, 이메일, 비밀번호 등의 정보를
        말합니다.
        <br />
        h. 콘텐츠 : 서비스에서 제공되는 기능에 의해 이용자가 입력하거나 회사에 의하여 이용자에게 제공되는 모든
        데이터(학습계획, 시간, 성취율 등에 관한 글, 음성, 음악, 이미지, 동영상 등 형태와 명칭을 불문함)를 말합니다.
        <br />
        i. 게시물 : 회원이 서비스를 이용함에 있어 사이트상에 게시한 콘텐츠 및 각종 파일과 링크 등을 말합니다.
      </p>
      2. 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 안내에서 정하는 바에
      의합니다.
      <br />
      <p className={'font-bold'}>제3조 (약관의 효력 및 개정)</p>
      1. 이 약관은 대한민국 내에서 서비스를 이용하는 모든 이용자에 대하여 그 효력이 발생합니다. 한편, 회사가 대한민국 외
      국가에서의 서비스가 가능한 경우, 이 약관은 해당 국가 내에서 서비스를 이용하고자 하는 모든 이용자에 대하여도 그
      효력이 발생합니다
      <br />
      2. 회사는 『약관의 규제에 관한 법률』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”)』,
      『콘텐츠산업진흥법』 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
      <br />
      3. 회사가 약관을 개정할 경우 적용일자 및 개정사유를 명시하여 현행 약관과 함께 사이트에 그 적용일자 7일(다만,
      회원에게 불리한 내용으로 변경하는 경우에는 30일) 이전부터 적용일자 전일까지 공지합니다.
      <br />
      4. 제3항에 의해 개정된 약관은 법령에 특별한 규정 또는 기타 부득이한 사유가 없는 한 회원에게 불리한 개정 내용은 그
      적용일자 이전으로 소급하여 적용되지 아니합니다.
      <br />
      5. 제3항에 따라 공지된 적용일자까지 회원이 명시적으로 거부의사를 표명하지 않을 경우에는 약관 개정에 동의한 것으로
      간주합니다.
      <br />
      6. 약관 개정에 거부의사를 표시한 회원에 대하여 회사는 개정 약관의 내용을 적용할 수 없으며, 해당 회원은 이용계약을
      해지할 수 있습니다. 회원에게 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에 회사가 이용계약을 해지할 수
      있습니다. 회사가 제공하는 서비스 중 특정 서비스에 대한 이용계약 해지의 효력과 관련하여서는, 해당 서비스에 대한
      별도의 약관 또는 이용지침이 있는 경우에는 그 약관 또는 이용지침의 관련 조항의 내용을 따릅니다.
      <br />
      7. 회사는 제공하는 서비스 중 특정 서비스에 대한 별도의 약관 또는 이용지침을 둘 수 있으며, 해당 서비스에 관하여
      별도로 적용되는 약관 또는 이용지침에 대한 동의는 회원이 개별 서비스를 최초로 이용하는 시점에 별도의 동의절차 통해
      이뤄지게 됩니다. 이 경우 해당 서비스에 대한 별도의 약관 또는 이용지침 등이 이 약관에 우선하여 적용됩니다.
      <br />
      8. 회사는 회원이 이 약관 전부를 인쇄할 수 있고 확인할 수 있도록 필요한 기술적 조치를 취합니다.
      <br />
      9. 이 약관은 회원이 이 약관에 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을 원칙으로 합니다. 단, 이 약관의 일부
      조항은 회원이 탈퇴 후에도 유효하게 적용될 수 있습니다.
      <br />
      <p className={'font-bold'}>회원가입과 이용계약</p>
      <p className={'font-bold'}>제4조 (회원가입을 통한 이용계약 체결)</p>
      1. 이용자는 회사가 정한 가입양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입이
      가능합니다.
      <br />
      2. 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자가 제5조 제1항 각 호에 해당하지 않는 한 회원으로
      등록합니다. 회사가 회원가입신청의 승인을 거부하거나 유보하는 경우 원칙적으로 이를 신청한 이용자에게 알립니다.
      <br />
      3. 이용계약의 성립시기는 이용자의 회원가입 신청에 대하여 회사가 가입완료를 신청절차 상에서 표시함으로써 회원가입
      신청을 승낙한 시점으로 합니다.
      <br />
      4. 회원의 권한은 이용계약을 한 자의 일신에 전속합니다.
      <br />
      <p className={'font-bold'}>제4조의2 (교육기관의 소속 학생의 서비스 이용 등)</p>
      1. 제4조에도 불구하고 회사와 교육기관(고등학교 등) 사이 학사행정 업무 위탁운영 계약에 의하여서도 해당
      교육기관(고등하교 등)의 소속 학생은 회원으로서 회사와 서비스 이용계약을 체결한 회원과 동일한 서비스를 제공받을 수
      있습니다.
      <br />
      2. 회사와 학사행정 업무 위탁운영 계약을 체결한 교육기관(고등학교 등)의 소속 학생으로서 회사의 회원이 된 회원에게는
      회사와 교육기관 사이 계약에 따라 본 약관의 일부 내용이 적용되지 않을 수 있습니다.
      <br />
      <p className={'font-bold'}>제5조 (이용계약 체결의 제한)</p>
      1. 회사는 다음 각 호에 해당하는 사유가 발생할 경우 회원의 회원가입 신청에 대한 승낙을 유보하는 등으로 회원가입 및
      이용계약 체결을 제한하거나, 사안의 경중에 따라서는 손해배상을 청구할 수 있습니다.
      <br />
      <p className={'pl-5'}>
        a. 회사의 업무상, 기술상의 사정으로 서비스 제공이 불가능한 경우
        <br />
        b. 실명이 아니거나 타인의 명의를 이용한 경우
        <br />
        c. 등록내용에 허위, 기재누락, 오기가 있는 경우
        <br />
        d. 회사가 요구하는 내용을 기재하지 않은 경우
        <br />
        e. 허위서류를 첨부하는 경우 등 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하여
        신청하는 경우
        <br />
        f. 사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우
        <br />
        g. 만 14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우
        <br />
        h. 가입신청자가 이 약관에 의하여 이전에 회원자격 상실 또는 이용계약 해제/해지 이력이 있는 경우, 단 회사의 회원
        재가입 승낙을 얻은 경우에는 예외로 함
        <br />
        i. 유료 서비스 이용 후 이용자가 회사에 등록한 결제수단의 임의해지 및 지급정지, 지불불능 등의 사유로 정당한 사유
        없이 회사가 청구한 서비스 요금을 납부하지 아니한 이력이 있는 경우
        <br />
        j. 범죄행위, 특정한 일정 기간 동안에 유료회원 가입, 해지를 반복하여 정상적인 용도 이외로 사용한 경우가 명백한
        경우 등 회사의 서비스 방해 등의 사유로 회원자격 상실(탈퇴) 또는 이용계약 해제/해지 이력이 있는 경우
        <br />
        k. 기타 회사가 정한 이용계약 체결 승낙 요건이 만족되지 않았을 경우
      </p>
      2. 이용신청에 대한 승낙 후에 제1항 각 호에 해당하는 사유가 발견되는 경우, 회사는 이 약관에 따라 체결된 서비스
      이용계약을 해지하거나 중단할 수 있습니다.
      <br />
      3. 회원의 자격 혹은 나이에 따라 서비스 이용의 일부가 제한될 수 있습니다.
      <br />
      <p className={'font-bold'}>제6조 (미성년자 회원가입에 대한 특칙)</p>
      1. 만 14세 미만의 이용자는 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은
      후에 회원가입을 신청하고 본인의 개인정보를 제공하여야 합니다.
      <br />
      2. 회사는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 만 14세 미만 이용자에 대하여는 가입을 취소 또는
      불허합니다.
      <br />
      3. 만 14세 미만 이용자의 부모 등 법정대리인은 아동에 대한 개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한
      동의를 철회할 수 있으며, 이러한 경우에 회사는 지체 없이 필요한 조치를 취해야 합니다.
      <br />
      <p className={'font-bold'}>제7조 (회원정보 변경)</p>
      1. 회원은 이용계약 체결시 등록한 사항에 변경이 있는 경우 회사에 회원정보 수정이나 이메일 또는 기타의 방법으로
      방법으로 회사에 변경사항을 알려야 합니다.
      <br />
      2. 회원은 회원정보 관리화면을 통하여 언제든 본인의 개인정보를 열람하고 수정할 수 있습니다.
      <br />
      3. 제1항에 따라 이용계약 체결시 등록한 사항의 변경을 회사에 알리지 않아 해당 회원에게 발생한 불이익에 대하여
      회사는 책임지지 않습니다.
      <br />
      <p className={'font-bold'}>제8조 (개인정보보호)</p>
      1. 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.
      <br />
      2. 회원 개인정보의 보호 및 사용에 대해서는 관련법, 회사와 학사행정 업무 위탁운영 계약을 체결한 교육기관(고등학교
      등) 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 사이트 이외의 링크된 사이트에서는 교육기관(고등학교
      등)/회사의 개인정보처리방침이 적용되지 않습니다.
      <br />
      <p className={'font-bold'}>제9조 (회원의 회원정보 관리의무)</p>
      1. 회원 본인의 회원정보에 관한 관리책임은 회원 본인에게 있으며, 이를 제3자가 이용하도록 하여서는 아니됩니다.
      <br />
      2. 회원은 본인의 회원정보가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의
      안내와 지침에 따라야 합니다.
      <br />
      3. 제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한
      불이익에 대하여 회사는 책임지지 않습니다.
      <br />
      <p className={'font-bold'}>제10조 (회원에 대한 통지)</p>
      1. 이용계약 체결 이후 회사는 회원에 대하여 회원이 미리 지정한 이메일이나 SMS 등을 이용하여 통지할 수 있습니다.
      <br />
      2. 회사가 불특정다수 회원에 대한 통지를 할 경우 1주일 이상 사이트의 공지사항에 게시함으로써 개별 통지를 대신할 수
      있습니다. 다만, 회원 본인의 서비스 이용에 관련하여 중대한 영향을 미치는 사항에 대해서는 개별 통지합니다.
      <br />
      3. 회원이 이메일 또는 SMS 수신을 동의하지 않았더라도 서비스 이용이 불가능한 중대한 사유가 발생할 경우, 회사는 이를
      고지하기 위한 목적으로 회원에게 이메일 또는 SMS를 발송할 수 있습니다.
      <br />
      <p className={'font-bold'}>서비스 이용</p>
      <p className={'font-bold'}>제11조 (서비스의 이용 개시)</p>
      1. 회사는 회원의 회원가입 신청을 승낙하여 이용계약이 체결된 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우에는
      지정된 일자부터 서비스를 개시하며, 일부 유료 서비스의 경우에는 회사가 지정하는 수단으로 결제가 완료된 후 이용
      가능합니다.
      <br />
      2. 회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 사이트에 공시하거나 회원에게 이를
      통지합니다.
      <br />
      <p className={'font-bold'}>제12조 (서비스의 이용시간)</p>
      1. 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 정기점검 등 기타 회사의 업무상이나 기술상의 이유로
      서비스가 일시 중지될 수 있고, 또한 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다.
      <br />
      2. 회사는 원칙적으로 서비스의 일시 중지 사실 및 중지 기간을 사전에 공지하되, 이를 미리 공지할 수 없는 급박하거나
      불가피한 사정이 있을 때에는 사후에 즉시 공지합니다.
      <br />
      <p className={'font-bold'}>제13조 (서비스의 제공 및 변경)</p>
      1. 회사는 회원에게 ‘슈퍼플래너’, ‘슈퍼스페이스’, ‘슈퍼스쿨’ 및 회사가 운영하는 기타 서비스를 제공합니다.
      <br />
      2. 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 서비스의 전부 또는 일부를 변경할 수
      있습니다.
      <br />
      3. 회사가 제공하기로 하여 회원과 이용계약을 체결한 서비스의 내용을 제2항에 따라 변경할 경우에는 그 사유를 회원에게
      제10조에서 정한 방법으로 통지합니다.
      <br />
      4. 서비스 변경 내용이 중대하거나 회원에게 중대하게 불리한 경우에 회사는 회원에게 제10조에서 정한 방법으로 통지하고
      동의를 받습니다.
      <br />
      5. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에
      따릅니다.
      <br />
      <p className={'font-bold'}>제14조 (서비스의 중단)</p>
      1. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의
      제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제10조에 정한 방법으로 회원에게 통지합니다. 다만, 회사가
      사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
      <br />
      2. 회사가 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에 회사는
      제10조에서 정한 방법으로 통지하며, 이로 인하여 회원이 입은 인과관계가 입증된 실제 손해를 배상합니다. 다만, 회사가
      고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
      <br />
      3. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 변경 또는 중단할 수 있으며, 이에
      대하여 회원에게 별도의 보상을 하지 않습니다.
      <br />
      <p className={'font-bold'}>제15조 (정보의 제공 및 광고의 게재)</p>
      1. 회사는 회원의 서비스 이용과 관련한 서비스 고지, 관리 메시지 및 기타 정보, 그리고 회사의 서비스를 운영과 관련한
      각종 정보나 광고 등을 서비스 화면에 게재하거나 이메일 및 서신우편, SMS 등의 방법(이하 “이메일 등의 방법”)으로
      회원에게 제공할 수 있습니다.
      <br />
      2. 제1항과 관련하여 회사는 관련 법령에 따라 동의 의무가 면제되는 거래 관련 정보 및 고객 문의사항 등에 대한 답변
      등을 제외한 광고 등을 이메일 등의 방법을 통하여 회원에게 전송할 경우 회원의 사전 수신 동의를 받아서 전송합니다.
      <br />
      3. 제1항과 관련하여 회원은 관련 법령에 따른 거래 관련 정보 및 고객 문의사항 등에 대한 답변 등을 제외하고는
      언제든지 이메일 등의 방법에 의한 회사의 정보제공에 대하여 수신을 거절할 수 있으며, 회사는 수신거절 방법을
      명시합니다.
      <br />
      4. 회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신
      또는 거래를 하는 것은 전적으로 회원과 광고주 간의 문제입니다. 만약 회원과 광고주간에 문제가 발생할 경우에도 회원과
      광고주가 직접 해결하여야 하며, 이와 관련하여 회사는 어떠한 책임도 지지 않습니다.
      <br />
      <p className={'font-bold'}>제16조 (저작권의 귀속 및 이용제한)</p>
      1. 회사가 작성한 저작물에 대한 저작권 및 기타 지식재산권은 회사에 귀속합니다.
      <br />
      2. 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 콘텐츠 등을 이용할 수 있는 이용권만을
      부여하며, 회원은 서비스를 이용함으로써 얻는 정보 중 회사에게 지식재산권이 귀속된 정보를 회사의 사전 승낙 없이
      복제, 송신, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 아니됩니다.
      <br />
      <p className={'font-bold'}>제17조 (이용자의 게시물)</p>
      1. 이용자가 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 이용자에게 있으며, 회사는 게시물을 작성한 회원의
      동의 없이 해당 게시물을 다른 목적으로 사용할 수 없습니다.
      <p className={'pl-5'}>
        a. 회사는 이용자가 작성한 게시물이 다음 각 호에 해당한다고 판단되는 경우에 사전 통지 없이 삭제할 수 있고, 이에
        대하여 회사는 어떤 책임도 지지 않습니다.
        <br />
        b. 회사, 다른 이용자 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
        <br />
        c. 공서양속에 위반되는 내용일 경우
        <br />
        d. 범죄적 행위에 결부된다고 인정되는 경우
        <br />
        e. 회사 및 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
        <br />
        f. 이용자가 사이트 내 음란물을 게재하거나 음란사이트를 링크하는 경우
        <br />
        g. 회사로부터 사전 승인받지 아니한 광고, 판촉내용을 게시하는 경우
        <br />
        h. 회사에서 제공하는 서비스와 관련 없는 내용인 경우
        <br />
        i. 정당한 사유 없이 회사의 영업을 방해하는 내용인 경우
        <br />
        j. 기타 관련법령 및 회사의 이용지침 등에 위반된다고 판단되는 경우
      </p>
      2. 회사는 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에 따라 각종 게시물(회원간
      전달을 포함)을 등록하거나 삭제하여야 합니다.
      <br />
      3. 회원탈퇴 이후 회원이 작성하였던 게시물 및 댓글 등은 삭제되지 않으며, 회원탈퇴로 인하여 회원정보가 삭제되어
      작성자 본인을 확인할 수 없으므로 게시물 편집 및 삭제가 원천적으로 불가합니다. 회원이 작성한 게시물의 삭제를 원할
      경우에는 회원탈퇴 이전에 게시물을 모두 삭제하여야 합니다.
      <br />
      4. 회원탈퇴 전 삭제하지 않은 해당 게시물에 대한 관리 권한은 회사로 이전됩니다. 단, 해당 게시물에 대한 저작권은
      탈퇴한 회원에게 귀속됩니다.
      <br />
      5. 회사가 회원이 작성한 게시물을 서비스 운영 또는 마케팅에 활용하는 경우 회원에게 별도 동의를 받습니다.
      <br />
      <p className={'font-bold'}>제18조 (유료 서비스 및 유료 서비스에 관한 이용지침)</p>
      1. 회사는 회원이 별도 대금지급 없이 이용할 수 있는 무료 서비스와 회사가 미리 책정한 요금을 지불하고 이용하는 유료
      서비스를 제공하며, 회원은 이를 선택하여 이용할 수 있습니다.
      <br />
      2. 회사가 제공하는 유료 서비스의 종류, 요금 대금지급방법 등은 회사가 시행하는 별도의 각 유료 서비스 약관 또는
      이용지침에 따르며, 회사는 각 유료 서비스 약관 또는 이용지침을 사이트 및 유료 서비스 구입 페이지를 통해 회원에게
      공지합니다.
      <br />
      <p className={'font-bold'}>당사자의 의무</p>
      <p className={'font-bold'}>제19조 (회사의 의무)</p>
      1. 회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고,
      안정적인 서비스를 제공하는데 최선을 다해야 합니다.
      <br />
      2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보 보호를 위한 보안시스템을 갖추어야 하며,
      개인정보취급방침을 게시하고 준수합니다.
      <br />
      3. 회사는 회원이 동의하지 않은 영리목적의 광고성 이메일을 발송하지 않습니다.
      <br />
      4. 회사는 회원이 제기한 의견이나 불만의 정당성이 객관적인 관점에서 인정될 경우에는, 적절한 절차를 거쳐 처리하여야
      합니다. 다만, 즉시처리가 곤란한 경우 회원에게 그 사유와 처리일정을 통보하여야 합니다.
      <br />
      5. 회사는 업무와 관련하여 회원의 사전 동의 없이 회원 전체 또는 일부의 개인정보에 관한 통계자료를 작성하여 이를
      사용할 수 있고, 이를 위하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나
      쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있으며, 쿠키의 설정 변경에 의해
      서비스 이용이 제한되는 것은 회원의 책임입니다.
      <br />
      <p className={'font-bold'}>제20조 (회원의 의무)</p>
      1. 회원은 다음 행위를 하여서는 아니됩니다.
      <p className={'pl-5'}>
        a. 회원가입 및 이용계약 체결시 허위 사실을 기재하거나, 타인의 이메일 및 비밀번호를 도용, 부정하게 사용하는 행위
        <br />
        b. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는
        행위
        <br />
        c. 서비스 내에서 제공받은 콘텐츠를 서비스 이용범위 내의 사적 목적으로 이용하는 것 외에, 공공장소 및 영리를
        목적으로 하는 영업장, 매장 등에서 재생하는 등의 방법으로 이용하는 행위
        <br />
        d. 타인의 명예를 손상시키거나 불이익을 주는 행위
        <br />
        e. 게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위
        <br />
        f. 회사 및 제3자의 저작권 등 기타 권리를 침해하는 행위
        <br />
        g. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위
        <br />
        h. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는
        유포하는 행위
        <br />
        i. 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인
        수신거부의사에 반하여 광고성 정보를 전송하는 행위 또는 (불법)스팸을 전송하는 행위
        <br />
        j. 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위
        <br />
        k. 다른 회원의 개인정보를 수집, 저장, 공개하는 행위
        <br />
        l. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를 유통시키는 행위
        <br />
        m. 재물을 걸고 도박하거나 사행행위를 하는 행위
        <br />
        n. 윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위
        <br />
        o. 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의
        일상적 생활을 방해하는 행위
        <br />
        p. 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포함)의 전송 또는 게시 행위
        <br />
        q. 회사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위
        <br />
        r. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어
        바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 이메일으로 발송하는 행위
        <br />
        s. 스토킹(stalking) 등 다른 회원을 괴롭히는 행위
        <br />
        t. 기타 불법적이거나 부당하다고 합리적으로 인정되는 행위
        <br />
        u. 기타 관계 법령, 이 약관의 규정, 서비스와 관련하여 회사가 공지한 주의사항 및 이용안내, 회사가 통지하는 사항
        등을 위반하는 행위
        <br />
        v. 기타 회사의 업무에 방해되는 행위
        <br />
      </p>
      2. 회원이 수집하여 서비스 내에 게시한 정보에 대한 책임은 회원에게 있으며, 그에 대한 법적 책임은 회원이 부담합니다.
      <br />
      <p className={'font-bold'}>계약해지 및 이용제한</p>
      <p className={'font-bold'}>제21조 (회원탈퇴)</p>
      1. 회원은 고객센터 및 서비스 내 회원탈퇴 절차를 통하여 서비스 이용계약을 해지(이하 “회원탈퇴”)할 수 있습니다.
      회원이 회원탈퇴를 신청하는 경우 회사는 회원 본인 여부를 확인할 수 있으며, 해당 회원이 본인으로 확인되는 경우에
      회원의 신청에 따른 조치를 취합니다.
      <br />
      2. 회원탈퇴시 회원으로서의 모든 혜택이 소멸됩니다. 회원탈퇴로 인해 소멸되는 기존 데이터 및 혜택은 복구가
      불가능하며, 회사는 이를 복구할 책임이 없습니다.
      <br />
      3. 회원이 탈퇴할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시
      회원의 모든 정보는 파기됩니다. 다만 회원이 게시한 게시물의 처리의 경우는 본 약관 제17조에 따릅니다.
      <br />
      4. 회원탈퇴로 인해 파기된 정보에 대하여 회사는 차후 복구의 의무를 부담하지 않으므로 회원은 탈퇴 전 필요한 정보를
      백업해 두어야 합니다.
      <br />
      <p className={'font-bold'}>제22조 (회사의 회원자격 제한 및 정지, 이용계약 해제 또는 해지 등)</p>
      1. 회사는 회원이 이 약관에 규정한 회원의 의무를 이행하지 아니하거나 제5조 제1항 각 호의 회원가입 및 이용계약 체결
      제한 사유를 사후에 발견한 경우, 회원의 서비스 이용 제한, 회원자격 제한 및 정지, 이용계약 해제 또는 해지 등의
      조치를 취할 수 있습니다.
      <br />
      2. 회사가 회원에 대하여 제1항과 같은 조치를 취하고자 할 경우, 최소한 15일 이상의 기간을 정하여 소명할 기회를
      부여하며, 회사는 회원의 이의가 정당하다고 인정하는 경우 즉시 서비스의 이용을 재개합니다.
      <br />
      3. 회사가 회원자격을 제한 또는 정지시킨 후 동일한 행위가 2회 이상 반복되거나 15일 이내에 그 사유가 시정되지
      아니하는 경우 회사는 이용계약을 해제 또는 해지할 수 있습니다.
      <br />
      4. 회원이 회사의 업무에 방해되는 행위를 하였다고 판단될 경우 회사는 즉시 이용계약을 해제 또는 해지할 수 있으며,
      회사는 이용계약의 해제 또는 해지와 무관하게 회원에게 별도의 손해배상을 청구할 수 있습니다.
      <br />
      5. 회원이 이 약관이나 현행법의 위반 및 고의 또는 중대한 과실로 회사에 손해를 입힌 경우에는 회사는 사전 통보 없이
      이용계약을 해제 또는 해지할 수 있습니다. 이 경우 회원은 유료 서비스의 사용권한을 상실하고 이로 인한 환불 및
      손해배상을 청구할 수 없습니다.
      <br />
      6. 회사가 이용계약을 해제 또는 해지하는 경우 회사는 회원에게 서면, 이메일 또는 이에 준하는 방법으로 해지사유 및
      해지일 등을 회원에게 통보합니다.
      <br />
      <p className={'font-bold'}>제23조 (양도금지)</p>
      회원은 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 게시물에 대한 저작권을 포함한
      모든 권리 및 책임은 이를 게시한 회원에게 있습니다.
      <br />
      <p className={'font-bold'}>손해배상 등</p>
      <p className={'font-bold'}>제24조 (손해배상)</p>
      1. 회사의 고의 또는 중대한 과실로 인하여 회원에게 손해가 발생한 경우 회사는 회원에게 실제 발생한 손해만을
      배상합니다.
      <br />
      2. 회원이 서비스를 이용함에 있어 행한 불법행위나 이 약관 위반행위로 인하여 회사가 해당 회원 이외의 제3자로부터
      손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우, 해당 회원은 스스로의 책임과 비용으로 회사를 면책하여야
      하며, 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.
      <br />
      <p className={'font-bold'}>제25조 (면책사항)</p>
      1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이
      면제됩니다.
      <br />
      2. 회사는 이용자의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다.
      <br />
      3. 회사는 이용자가 서비스를 이용함으로써 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖에 서비스를
      통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다.
      <br />
      4. 회사는 이용자가 사이트에 게재한 정보, 자료, 사실의 신뢰도 및 정확성 등 내용에 대하여는 책임을 지지 않습니다.
      회사는 직접 생산하지 않은 콘텐츠(학교 공지사항, 급식표 등 학교소식을 포함한 이용자/제휴업체 측의 콘텐츠) 내 정보에
      대하여도 정확성 및 신뢰도를 검증할 방법이 없으므로 어떠한 책임도 지지 않습니다.
      <br />
      5. 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로
      인해 발생한 손해를 배상할 책임도 없습니다.
      <br />
      6. 회사는 무료로 제공되는 서비스 이용과 관련하여서는 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
      <br />
      <p className={'font-bold'}>제26조 (준거법 및 재판관할)</p>
      1. 이 약관은 대한민국 법률에 따라 규율되고 해석됩니다.
      <br />
      2. 이 약관 및 서비스 이용과 관련하여 회사와 회원 사이에 발생한 분쟁으로 소송이 제기되는 경우 대한민국법을
      준거법으로 하며, 『민사소송법』에 따라 관할법원을 정합니다.
      <br />
      <p className={'font-bold'}>부칙</p>
      <p className={'font-bold'}>제1조 (시행일)</p>본 약관은 2022. 3. 2.부터 시행한다.
    </div>
  );
}
