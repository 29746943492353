import _, { range } from 'lodash';
import { useEffect, useState } from 'react';
import {
  useStudentExamScoreDelete,
  useStudentMockExamScoreDelete,
  useStudentScoreFileCheck,
  useStudentScoreFileCheckMock,
} from 'src/container/insert-exam-score';
import { useAdminCommonFindAllKlassBySchool, useSchoolManagementGetSchoolInfo } from 'src/generated/endpoint';
import {
  StudentExamScoreDeleteMockScoreParams,
  StudentExamScoreDeleteStudentExamScoreParams,
} from 'src/generated/model';
import { getThisYear } from 'src/util/time';
import { Blank, Select } from '../common';
import { Admin } from '../common/Admin';
import AlertDialog from '../common/AlertDialog';
import ConfirmDialog from '../common/ConfirmDialog';
import { ExamUploadModal } from './ExamUploadModal';

export const AcademicRecordsComponent = () => {
  // const thisYear = new Date().getFullYear();
  const thisYear = Number(getThisYear());
  const [startYear, setStartYear] = useState(thisYear);
  const [year, setYear] = useState(new Date().getFullYear());
  const [grade, setGrade] = useState(1);
  const [semester, setSemester] = useState(1);
  const [step, setStep] = useState(0);
  const [klass, setKlass] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [modalOpen, setModalClose] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const deleteScoreFile = (step: number, klass: number) => {
    setStep(step);
    setKlass(klass);
    setDialogOpen(true);
  };

  const { data: SCORE, isLoading } = useStudentScoreFileCheck(grade, year);
  const { data: MOCKSCORE, isLoading: isLoadingM } = useStudentScoreFileCheckMock(grade, year, semester);

  const { data: school } = useSchoolManagementGetSchoolInfo();
  const { data: klasses } = useAdminCommonFindAllKlassBySchool({ year });
  const { deleteExamScore } = useStudentExamScoreDelete();
  const { deleteMockExamScore } = useStudentMockExamScoreDelete();
  const uniqueGrades = _.uniq(_.map(klasses, 'grade'));

  const handleDeleteScoreFile = ({
    grade,
    classNum,
    semester,
    insertionYear,
  }: StudentExamScoreDeleteStudentExamScoreParams) => {
    deleteExamScore({ grade, classNum, semester, insertionYear });
  };

  const handleDeleteMockScoreFile = ({
    grade,
    classNum,
    semester,
    step,
    insertionYear,
  }: StudentExamScoreDeleteMockScoreParams) => {
    deleteMockExamScore({ grade, classNum, semester, step, insertionYear });
  };

  const handleConfirmDelete = () => {
    if (step === 1 || step === 2) {
      handleDeleteMockScoreFile({
        grade,
        classNum: klass,
        semester,
        step,
        insertionYear: year,
      });
    } else {
      handleDeleteScoreFile({
        grade,
        classNum: klass,
        semester: -semester,
        insertionYear: year,
      });
    }
    setDialogOpen(false);
    setAlertOpen(true);
  };

  useEffect(() => {
    if (!school?.createdAt) return;
    setStartYear(new Date(school.createdAt).getFullYear());
  }, [school?.createdAt]);

  // 성적이 등록된 학급 정보를 추출
  const registeredClasses = SCORE?.scores || [];
  const mockScores = MOCKSCORE?.scores || [];

  // 학년별 학급 정보 분리
  const classesByGrade = _.groupBy(klasses, 'grade');

  return (
    <Admin.Section className="w-full gap-0 px-0 py-0">
      {isLoading || isLoadingM ? (
        <Blank />
      ) : (
        <>
          <section className="mb-6 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Select value={year} onChange={(e) => setYear(Number(e.target.value))}>
                {range(thisYear, startYear - 1, -1).map((year) => (
                  <option key={year} value={year}>
                    {year}학년도
                  </option>
                ))}
              </Select>

              <Select value={grade} onChange={(e) => setGrade(Number(e.target.value))}>
                {uniqueGrades.map((grade) => (
                  <option key={grade} value={grade}>
                    {grade}학년
                  </option>
                ))}
              </Select>

              <Select value={semester} onChange={(e) => setSemester(Number(e.target.value))}>
                {[1, 2].map((semester) => (
                  <option key={semester} value={semester}>
                    {semester}학기
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex items-center gap-2">
              <nav className="flex h-10 items-center gap-2 text-[15px] text-[#777777]">
                <a
                  href="https://kr.object.gov-ncloudstorage.com/superschool/storage/material/score/score_manual.pdf"
                  target="_blank"
                  download
                  className="flex items-center gap-1 pr-1"
                >
                  <p>업로드 가이드</p>
                  <div className="text-md relative flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-gray-500 text-sm text-[#777777]">
                    ?
                  </div>
                </a>
                {(registeredClasses.length > 0 || mockScores.length > 0) && (
                  <button
                    onClick={() => setIsUpdate((prev) => !prev)}
                    className={`min-w-[120px] rounded-lg border border-[#0066ff] bg-white px-3 py-2 text-[#0066ff]`}
                  >
                    {isUpdate ? '파일 수정 완료' : '등록 파일 수정'}
                  </button>
                )}
                <button
                  onClick={() => setModalClose(!modalOpen)}
                  className={`min-w-[120px] rounded-lg border border-[#0066ff] bg-[#0066ff] px-3 py-2 text-white`}
                >
                  파일 업로드
                </button>
              </nav>
            </div>
          </section>
          <Admin.Table className="mb-10 rounded-lg bg-white px-4 py-3 text-center">
            <Admin.TableHead className="border-b border-b-[#333333]">
              <Admin.TableRow>
                <Admin.TableHCell className="w-1/4 text-center text-lg font-semibold text-[#333333]" children="학급" />
                <Admin.TableHCell
                  className="w-1/4 text-center text-lg font-semibold text-[#333333]"
                  children="1차 지필"
                />
                <Admin.TableHCell
                  className="w-1/4 text-center text-lg font-semibold text-[#333333]"
                  children="2차 지필"
                />
                <Admin.TableHCell
                  className="w-1/4 text-center text-lg font-semibold text-[#333333]"
                  children="종합 성적"
                />
              </Admin.TableRow>
            </Admin.TableHead>
            <Admin.TableBody>
              {(classesByGrade[grade] || []).map((klass, i) => {
                const registeredScore = registeredClasses.find(
                  (score) =>
                    score.class === klass.klass && score.grade === grade && [semester, -1, -2].includes(score.semester),
                );
                const mockScore = MOCKSCORE
                  ? mockScores.find(
                      (score) =>
                        score.class === klass.klass && MOCKSCORE.grade === grade && MOCKSCORE.semester === semester,
                    )
                  : undefined;
                const isLastRow = i === classesByGrade[grade].length - 1;
                return (
                  <Admin.TableRow
                    key={`${klass.klass}-${i}`}
                    className={isLastRow ? 'rounded-bl-lg rounded-br-lg' : ''}
                  >
                    <Admin.TableCell
                      className="h-14 text-lg font-normal text-[#333333]"
                      children={`${klass.grade}학년 ${klass.klass}반`}
                    />
                    {isUpdate ? (
                      <>
                        <Admin.TableCell
                          className="text-lg font-normal"
                          children={
                            mockScore?.first_test ? (
                              <Admin.Box className="flex items-center justify-center gap-1">
                                <button
                                  className="rounded-lg border border-[#ff2525;] px-4 py-1 text-[15px] font-bold text-[#ff2525;]"
                                  onClick={() => deleteScoreFile(1, klass.klass)}
                                >
                                  삭제
                                </button>
                              </Admin.Box>
                            ) : (
                              '미등록'
                            )
                          }
                          style={{ color: mockScore?.first_test ? '#0066ff' : '#aaaaaa' }}
                        />
                        <Admin.TableCell
                          className="text-lg font-normal"
                          children={
                            mockScore?.secont_test ? (
                              <Admin.Box className="flex items-center justify-center gap-1">
                                <button
                                  className="rounded-lg border border-[#ff2525;] px-4 py-1 text-[15px] font-bold text-[#ff2525;]"
                                  onClick={() => deleteScoreFile(2, klass.klass)}
                                >
                                  삭제
                                </button>
                              </Admin.Box>
                            ) : (
                              '미등록'
                            )
                          }
                          style={{ color: mockScore?.secont_test ? '#0066ff' : '#aaaaaa' }}
                        />
                        <Admin.TableCell
                          className="text-lg font-normal"
                          children={
                            registeredScore ? (
                              <Admin.Box className="flex items-center justify-center gap-1">
                                <button
                                  className="rounded-lg border border-[#ff2525;] px-4 py-1 text-[15px] font-bold text-[#ff2525]"
                                  onClick={() => deleteScoreFile(3, klass.klass)}
                                >
                                  삭제
                                </button>
                              </Admin.Box>
                            ) : (
                              '미등록'
                            )
                          }
                          style={{ color: registeredScore ? '#0066ff' : '#aaaaaa' }}
                        />
                      </>
                    ) : (
                      <>
                        <Admin.TableCell
                          className="text-lg font-normal"
                          children={mockScore?.first_test ? '등록 완료' : '미등록'}
                          style={{ color: mockScore?.first_test ? '#0066ff' : '#aaaaaa' }}
                        />
                        <Admin.TableCell
                          className="text-lg font-normal"
                          children={mockScore?.secont_test ? '등록 완료' : '미등록'}
                          style={{ color: mockScore?.secont_test ? '#0066ff' : '#aaaaaa' }}
                        />
                        <Admin.TableCell
                          className="text-lg font-normal"
                          children={registeredScore ? '등록 완료' : '미등록'}
                          style={{ color: registeredScore ? '#0066ff' : '#aaaaaa' }}
                        />
                      </>
                    )}
                  </Admin.TableRow>
                );
              })}
            </Admin.TableBody>
          </Admin.Table>
        </>
      )}

      {dialogOpen && (
        <ConfirmDialog
          cancelText="취소"
          description={`${year}학년도 ${grade}학년 ${klass}반의 ${semester}학기<br/>${
            step === 1 ? '1차 지필' : step === 2 ? '2차 지필' : '종합 성적'
          } 파일을 삭제하시겠습니까?`}
          confirmText="삭제"
          message="파일을 삭제하시겠습니까?"
          onCancel={() => setDialogOpen(!dialogOpen)}
          onConfirm={handleConfirmDelete}
          theme="delete"
        />
      )}
      {modalOpen && (
        <ExamUploadModal
          modalOpen={modalOpen}
          setModalClose={() => setModalClose(false)}
          width="w-[480px]"
          grade={grade}
          semester={semester}
          ablePropragation={true}
        />
      )}
      {alertOpen && (
        <AlertDialog
          message="삭제가 완료되었습니다."
          description={`${year}학년도 ${grade}학년 ${klass}반의 ${semester}학기<br/>${
            step === 1 ? '1차 지필' : step === 2 ? '2차 지필' : '종합 성적'
          } 파일이 정삭적으로 삭제되었습니다.`}
          confirmText="확인"
          onConfirm={() => setAlertOpen(!alertOpen)}
          theme="secondary"
        />
      )}
    </Admin.Section>
  );
};
